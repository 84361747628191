import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  FieldCheckboxGroup,
  FieldRadioButton,
} from '../../../components';

import css from './SignupForm.module.css';
import { companyType } from '../../../config/configListing';
import { checkUser } from '../../../util/api';

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    keepDirtyOnReinitialize={true}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        isorgansationId,
        firstname,
        lastname,
        invitedEmailSent,
        isSaleAgency,
        organsationEmail,
        isdecrypted,
        companyIndustry,
        values,
        form,
        termsAndConditions,
      } = fieldRenderProps;
      // email

      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const [isEmailExist, setEmailExist] = useState({ status: false, message: '' });

      const handleChange = async e => {
        e.preventDefault();
        form.change('email', e.target.value);
        try {
          const response = await checkUser({ email: e.target.value });
          if (response.status) {
            setEmailExist({ status: true, message: response.message });
          } else {
            setEmailExist({ status: false, message: '' });
          }
        } catch (error) {
          console.log('error', error);
        }
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={intl.formatMessage({
                  id: 'SignupForm.firstNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.firstNamePlaceholder',
                })}
                disabled={firstname ? true : false}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.firstNameRequired',
                  })
                )}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'SignupForm.lastNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.lastNamePlaceholder',
                })}
                disabled={lastname ? true : false}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.lastNameRequired',
                  })
                )}
              />
            </div>
            {isdecrypted || invitedEmailSent ? null : (
              <FieldTextInput
                className={css.signUpInput}
                type="text"
                id={formId ? `${formId}.companyName` : 'companyName'}
                name="companyName"
                label={intl.formatMessage({
                  id: 'SignupForm.companyNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.companyNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.companyNameRequired',
                  })
                )}
              />
            )}

            <FieldTextInput
              className={css.signUpInput}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={intl.formatMessage({
                id: invitedEmailSent ? 'Email' : 'SignupForm.emailLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.emailPlaceholder',
              })}
              disabled={isdecrypted || invitedEmailSent || organsationEmail ? true : false}
              validate={validators.composeValidators(emailRequired, emailValid)}
              onChange={handleChange}
            />
            {isEmailExist.status && <span style={{ color: 'red' }}>{isEmailExist.message}</span>}
            {invitedEmailSent ? (
              <FieldTextInput
                className={css.signUpInput}
                type="text"
                id={`${formId}.saleagencyName`}
                name="saleagencyName"
                label={intl.formatMessage({
                  id: 'SignupForm.saleagencyNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.saleagencyNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.saleagencyNameRequired',
                  })
                )}
              />
            ) : null}

            {isdecrypted || invitedEmailSent ? null : (
              <FieldSelect
                className={css.signUpInput}
                id={`${formId}.companyIndustry`}
                name="companyIndustry"
                label={intl.formatMessage({ id: 'SignupForm.companyIndustry' })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.companyIndustryRequired',
                  })
                )}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'SignupForm.companyIndustryOption' })}
                </option>
                {companyIndustry.map(({ option, label }) => (
                  <option key={option} value={option}>
                    {label}
                  </option>
                ))}
              </FieldSelect>
            )}

            <FieldTextInput
              className={classNames(css.password, css.signUpInput)}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={intl.formatMessage({
                id: 'SignupForm.passwordLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />
          </div>
          <div className={css.signUpInput}>
            {isdecrypted || invitedEmailSent ? null : (
              <>
                {companyType.map(num => (
                  <div>
                    <FieldRadioButton
                      // className={css.features}
                      id={num.key}
                      key={num.key}
                      name={'companyType'}
                      value={num.key}
                      label={num.label}
                      validate={passwordValidators}
                    />
                  </div>
                ))}
              </>
            )}
          </div>

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            {isdecrypted ? (
              <div>
                <FieldCheckboxGroup
                  name="employeeterms"
                  id={formId ? `${formId}.employeeterms` : 'employeeterms'}
                  optionLabelClassName={css.finePrint}
                  options={[
                    {
                      key: 'tos-and-privacy',
                      label: intl.formatMessage(
                        { id: 'AuthenticationPage.termsAndConditionsEmployee' }
                        // { termsLink, privacyLink }
                      ),
                    },
                  ]}
                  validate={validators.requiredFieldArrayCheckbox(
                    intl.formatMessage({
                      id: 'AuthenticationPage.termsAndConditionsemployeeRequired',
                    })
                  )}
                />
              </div>
            ) : null}

            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled || isEmailExist.status}
            >
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
