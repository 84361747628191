import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton } from '../../../components';

import css from './SignupForm.module.css';
const Company_ContractComponent = props => (


  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        isLoading,
        values,
        close,

      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress 
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.welcomeModalContent}>
      
            <label className={css.selectLabel}>Get your PDF contract via docusign</label>
          
                        {isLoading ? <div>Please wait Loading...</div> :  <PrimaryButton type="submit" inProgress={submitInProgress} >
                          Get Contract and Sign
                        </PrimaryButton>}

          </div>

        </Form>
      );
    }}
  />
);

Company_ContractComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

Company_ContractComponent.propTypes = {
  inProgress: bool,


  // from injectIntl
  intl: intlShape.isRequired,
};

const Company_Contract = compose(injectIntl)(Company_ContractComponent);
Company_Contract.displayName = 'Company_Contract';

export default Company_Contract;
